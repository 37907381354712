<!-- src/components/Plans.vue -->
<template>
  <div class="plans-container container my-5">
    <!-- Botón Volver al Dashboard -->
    <div class="mb-4">
      <router-link to="/dashboard" class="btn btn-secondary">
        Volver al Dashboard
      </router-link>
    </div>

    <!-- Título de la Página -->
    <div class="andes-card andes-card--flat andes-card--padding-16 mb-4">
      <div class="header">
        <h1 class="title">
          Casas, departamentos en arriendo y venta - Ubbi.cl 🏠
        </h1>
        <p></p>
      </div>
    </div>

    <!-- Sección de Planes Mensuales -->
    <section data-testid="section-component">
      <h2 class="mb-4">Planes Mensuales</h2>
      <table class="table table-bordered plans-table">
        <thead class="thead-light">
          <tr>
            <th scope="col"></th>
            <th scope="col">30 publicaciones plata</th>
            <th scope="col">100 publicaciones plata</th>
            <th scope="col">200 publicaciones plata</th>
            <th scope="col">Más de 200 Publicaciones</th>
          </tr>
        </thead>
        <tbody>
          <!-- Precios de los Planes -->
          <tr>
            <td class="text-left font-weight-bold">Precio</td>
            <td class="text-center">UF <strong>2</strong> + IVA</td>
            <td class="text-center">UF <strong>3</strong> + IVA</td>
            <td class="text-center">UF <strong>4</strong> + IVA</td>
            <td class="text-center">DESDE UF <strong>10</strong> + IVA</td>
          </tr>
          <!-- Botones de Solicitar -->
          <tr>
            <td class="text-left font-weight-bold">Acción</td>
            <td class="text-center">
              <button
                class="btn btn-quiet btn-large"
                @click="openModal(plans[0])">
                Solicitar
              </button>
            </td>
            <td class="text-center">
              <button
                class="btn btn-loud btn-large"
                @click="openModal(plans[1])">
                Solicitar
              </button>
            </td>
            <td class="text-center">
              <button
                class="btn btn-quiet btn-large"
                @click="openModal(plans[2])">
                Solicitar
              </button>
            </td>
            <td class="text-center">
              <button
                class="btn btn-quiet btn-large"
                @click="openModal(plans[3])">
                Solicitar
              </button>
            </td>
          </tr>
        </tbody>
      </table>

    </section>

    <!-- Modal para Solicitar Plan -->
    <div class="modal fade" id="requestPlanModal" tabindex="-1" aria-labelledby="requestPlanModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="submitPlanRequest">
            <div class="modal-header">
              <h5 class="modal-title" id="requestPlanModalLabel">Solicitar Plan</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <h6>{{ selectedPlan.name }}</h6>
              <p>{{ selectedPlan.description }}</p>
              <input type="hidden" v-model="planRequest.plan" name="plan">

              <p><span class="text-danger">*</span> Campos obligatorios.</p>

              <table class="table">
                <tbody>
                  <!-- Nombre -->
                  <tr>
                    <td class="text-left font-weight-bold">Nombre*</td>
                    <td>
                      <input 
                        type="text" 
                        v-model="planRequest.full_name" 
                        name="full_name" 
                        class="form-control" 
                        required 
                        maxlength="120">
                    </td>
                  </tr>
                  <!-- Email -->
                  <tr>
                    <td class="text-left font-weight-bold">Email*</td>
                    <td>
                      <input 
                        type="email" 
                        v-model="planRequest.email" 
                        name="email" 
                        class="form-control" 
                        required 
                        maxlength="120">
                    </td>
                  </tr>
                  <!-- Teléfono -->
                  <tr>
                    <td class="text-left font-weight-bold">Teléfono*</td>
                    <td>
                      <div class="row">
                        <div class="col-3">
                          <input 
                            type="number" 
                            v-model="planRequest.area_code" 
                            name="area_code" 
                            class="form-control" 
                            required 
                            maxlength="3" 
                            placeholder="Cod. área">
                        </div>
                        <div class="col-9">
                          <input 
                            type="number" 
                            v-model="planRequest.phone_number" 
                            name="phone_number" 
                            class="form-control" 
                            required 
                            maxlength="10" 
                            placeholder="Número">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- Empresa -->
                  <tr>
                    <td class="text-left font-weight-bold">Empresa*</td>
                    <td>
                      <input 
                        type="text" 
                        v-model="planRequest.company" 
                        name="company" 
                        class="form-control" 
                        required 
                        maxlength="120">
                    </td>
                  </tr>
                  <!-- Comentario -->
                  <tr>
                    <td class="text-left font-weight-bold">Comentario*</td>
                    <td>
                      <textarea 
                        v-model="planRequest.comment" 
                        name="comment" 
                        class="form-control" 
                        required 
                        maxlength="120" 
                        rows="3"></textarea>
                    </td>
                  </tr>
                  <!-- Términos y Condiciones -->
                  <tr>
                    <td colspan="2">
                      <div class="form-check">
                        <input 
                          type="checkbox" 
                          v-model="planRequest.tyc" 
                          id="tyc" 
                          class="form-check-input" 
                          required>
                        <label class="form-check-label" for="tyc">
                          Acepto los 
                          <a target="_blank" href="https://www.portalinmobiliario.com/pi/home/info/condiciones" rel="noreferrer">
                            Términos y Condiciones
                          </a>
                          y autorizo el uso de mis datos de acuerdo a la 
                          <a target="_blank" href="https://www.portalinmobiliario.com/privacidad" rel="noreferrer">
                            Declaración de Privacidad
                          </a>.
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">
                Volver a los Planes
              </button>
              <button type="submit" class="btn btn-primary" :disabled="!isFormValid">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref } from 'vue';
import { Modal } from 'bootstrap';

export default {
  name: 'PlansPage',
  data() {
    return {
      // Definición de los planes
      plans: [
        {
          id: 1,
          name: '30 publicaciones plata',
          description: 'Hasta 30 publicaciones en Plata.',
          price: 'UF 2 + IVA',
        },
        {
          id: 2,
          name: '100 publicaciones plata',
          description: 'Hasta 100 publicaciones en Plata.',
          price: 'UF 3 + IVA',
        },
        {
          id: 3,
          name: '200 publicaciones plata',
          description: 'Hasta 200 publicaciones en Plata.',
          price: 'UF 4 + IVA',
        },
        {
          id: 4,
          name: 'Más de 200 Publicaciones',
          description: 'Más de 200 publicaciones en Plata.',
          price: 'DESDE UF 10 + IVA',
        },
      ],
      selectedPlan: {}, // Plan seleccionado para solicitar
      modalInstance: null, // Instancia del modal de Bootstrap
      planRequest: { // Datos del formulario
        plan: '',
        full_name: '',
        email: '',
        area_code: '',
        phone_number: '',
        company: '',
        comment: '',
        tyc: false,
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.planRequest.full_name &&
        this.planRequest.email &&
        this.planRequest.area_code &&
        this.planRequest.phone_number &&
        this.planRequest.company &&
        this.planRequest.comment &&
        this.planRequest.tyc
      );
    },
  },
  methods: {
    // Abre el modal y establece el plan seleccionado
    openModal(plan) {
      this.selectedPlan = plan;
      this.planRequest.plan = plan.name;
      // Resetear campos del formulario
      this.planRequest.full_name = '';
      this.planRequest.email = '';
      this.planRequest.area_code = '';
      this.planRequest.phone_number = '';
      this.planRequest.company = '';
      this.planRequest.comment = '';
      this.planRequest.tyc = false;

      // Inicializar y mostrar el modal de Bootstrap
      if (!this.modalInstance) {
        const modalEl = document.getElementById('requestPlanModal');
        this.modalInstance = new Modal(modalEl);
      }
      this.modalInstance.show();
    },
    // Cierra el modal
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    // Maneja el envío del formulario
    submitPlanRequest() {
      // Aquí implementarás la lógica para enviar los datos al backend
      // Por ahora, simplemente mostramos los datos en la consola y una alerta
      console.log('Solicitud de Plan:', this.planRequest);
      alert('Solicitud enviada exitosamente.');

      // Cerrar el modal
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.plans-container {
  /* Puedes ajustar los estilos según tus necesidades */
}

.plans-table th,
.comparison-table th {
  background-color: #f8f9fa;
  text-align: center;
  vertical-align: middle;
}

.plans-table td,
.comparison-table td {
  text-align: center;
  vertical-align: middle;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.btn-quiet {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-quiet:hover {
  background-color: #5a6268;
  text-decoration: none;
  color: white;
}

.btn-loud {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-loud:hover {
  background-color: #0069d9;
  text-decoration: none;
  color: white;
}

.btn-large {
  font-size: 0.9rem;
}

/* Estilos para las imágenes de check */
img {
  width: 20px;
  height: 20px;
}

/* Estilos para el contenedor del dashboard */
.plans-container {
  padding: 20px;
}

/* Estilos para el modal */
.modal-body {
  /* Ajusta según necesites */
}

.font-weight-bold {
  font-weight: 600;
}
</style>
