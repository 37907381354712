<template>
    <div class="register-container">
      <form @submit.prevent="register">
        <h2>Crear Cuenta</h2>
        <div>
          <label for="email">Correo Electrónico</label>
          <input type="email" v-model="email" required />
        </div>
        <div>
          <label for="password">Contraseña</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Registrarse</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RegisterPage',
    data() {
      return {
        email: '',
        password: '',
        errorMessage: '',
      };
    },
    methods: {
      async register() {
        try {
          const { createdSessionId } = await this.$clerk.signUp.create({
            emailAddress: this.email,
            password: this.password,
          });
          await this.$clerk.setSession(createdSessionId);
          // Redirigir al usuario después del registro
          this.$router.push('/');
        } catch (error) {
          console.error('Error al registrar:', error);
          this.errorMessage = 'No se pudo crear la cuenta.';
        }
      },
    },
  };
  </script>
  