<template>
    <div class="login-container">
      <form @submit.prevent="login">
        <h2>Iniciar Sesión</h2>
        <div>
          <label for="email">Correo Electrónico</label>
          <input type="email" v-model="email" required />
        </div>
        <div>
          <label for="password">Contraseña</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Ingresar</button>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoginPage',
    data() {
      return {
        email: '',
        password: '',
        errorMessage: '',
      };
    },
    methods: {
      async login() {
        try {
          const { createdSessionId } = await this.$clerk.signIn.create({
            identifier: this.email,
            password: this.password,
          });
          await this.$clerk.setSession(createdSessionId);
          // Redirigir al usuario después de iniciar sesión
          this.$router.push('/');
        } catch (error) {
          console.error('Error al iniciar sesión:', error);
          this.errorMessage = 'Correo o contraseña incorrectos.';
        }
      },
    },
  };
  </script>
  