// src/store/index.js
import { createStore } from 'vuex';
import { getProperties } from '../services/api'; // Asegúrate de que esta ruta es correcta

// Función para obtener el valor de un atributo por su nombre
function getAttributeValue(attributes, name) {
  const attr = attributes.find(attr => attr.name === name);
  return attr ? attr.value : '';
}

// Función para procesar cada propiedad
function processProperty(propiedad) {
  return {
    id: propiedad.id,
    title: propiedad.title,
    description: propiedad.description,
    price: propiedad.price,
    currency: propiedad.currency,
    property_type: propiedad.property_type || getAttributeValue(propiedad.attributes, 'PropertyType'),
    transaction_type: propiedad.transaction_type || getAttributeValue(propiedad.attributes, 'TransactionType'),
    rooms: getAttributeValue(propiedad.attributes, 'Rooms'),
    bathrooms: getAttributeValue(propiedad.attributes, 'Bathrooms'),
    floor_area: getAttributeValue(propiedad.attributes, 'FloorArea'),
    plot_area: getAttributeValue(propiedad.attributes, 'PlotArea'),
    year: getAttributeValue(propiedad.attributes, 'Year'),
    parking: getAttributeValue(propiedad.attributes, 'Parking'),
    cellar: getAttributeValue(propiedad.attributes, 'Cellar'),
    orientation: getAttributeValue(propiedad.attributes, 'Orientation'),
    is_furnished: getAttributeValue(propiedad.attributes, 'IsFurnished') === 'True',
    agent_name: propiedad.agency ? propiedad.agency.contactname : '',
    agent_email: propiedad.agency ? propiedad.agency.contactemail : '',
    agent_phone: propiedad.agency ? propiedad.agency.contactphones : '',
    location: propiedad.location,
    images: propiedad.pictures ? propiedad.pictures.map(pic => pic.source) : [],
    url: propiedad.url,
  };
}

export default createStore({
  state: {
    properties: [],
    total: 0,
    tiposPropiedad: [],
    regiones: [],
    comunas: [],
    operaciones: [],
    loading: false,
    error: null,
    currentPage: 1,
    itemsPerPage: 12,
    currentFilters: {
      property_type: '',
      state: '',
      city: '',
      transaction_type: '',
    },
    filterOptionsFetched: false, // Nuevo estado para evitar solicitudes repetidas
  },
  mutations: {
    SET_PROPERTIES(state, properties) {
      state.properties = properties;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_TIPOS_PROPIEDAD(state, tipos) {
      state.tiposPropiedad = tipos;
    },
    SET_REGIONES(state, regiones) {
      state.regiones = regiones;
    },
    SET_COMUNAS(state, comunas) {
      state.comunas = comunas;
    },
    SET_OPERACIONES(state, operaciones) {
      state.operaciones = operaciones;
    },
    SET_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_ITEMS_PER_PAGE(state, items) {
      state.itemsPerPage = items;
    },
    SET_CURRENT_FILTERS(state, filters) {
      state.currentFilters = filters;
    },
    SET_FILTER_OPTIONS_FETCHED(state, fetched) {
      state.filterOptionsFetched = fetched;
    },
  },
  actions: {
    // Obtener propiedades basadas en filtros y paginación
    async fetchProperties({ commit, state }) {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);

      const params = {
        page: state.currentPage,
        page_size: state.itemsPerPage,
      };

      if (state.currentFilters.property_type) {
        params.property_type = state.currentFilters.property_type;
      }
      if (state.currentFilters.state) {
        params.state = state.currentFilters.state;
      }
      if (state.currentFilters.city) {
        params.city = state.currentFilters.city;
      }
      if (state.currentFilters.transaction_type) {
        params.transaction_type = state.currentFilters.transaction_type;
      }

      try {
        const response = await getProperties(params);
        if (response.data && response.data.properties) {
          const processedProperties = response.data.properties.map(propiedad => processProperty(propiedad));
          commit('SET_PROPERTIES', processedProperties);
          commit('SET_TOTAL', response.data.total);
        } else {
          commit('SET_ERROR', 'No se encontraron propiedades con los filtros aplicados');
          commit('SET_PROPERTIES', []);
          commit('SET_TOTAL', 0);
        }
      } catch (err) {
        console.error(err);
        if (err.response && err.response.status === 404) {
          commit('SET_ERROR', 'Ruta de API no encontrada (404)');
        } else {
          commit('SET_ERROR', 'Error al obtener datos');
        }
        commit('SET_PROPERTIES', []);
        commit('SET_TOTAL', 0);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    // Obtener las opciones de filtros de manera optimizada
    async fetchFilterOptions({ commit, state }) {
      // Evitar solicitudes repetidas si ya se han obtenido las opciones de filtros
      if (state.filterOptionsFetched) {
        console.log('Opciones de filtros ya obtenidas anteriormente.');
        return;
      }

      commit('SET_LOADING', true);
      try {
        const pageSize = 100; // Reducir el page_size para optimizar las solicitudes
        let currentPage = 1;
        let totalPages = 1;
        let allProperties = [];

        // Limitar el número de páginas para evitar cargar toda la data
        const maxPages = 5; // Ajusta este valor según tus necesidades

        while (currentPage <= totalPages && currentPage <= maxPages) {
          try {
            const response = await getProperties({ page: currentPage, page_size: pageSize });
            console.log(`Respuesta de la página ${currentPage}:`, response.data);

            if (response.data && response.data.properties) {
              const processed = response.data.properties.map(propiedad => processProperty(propiedad));
              allProperties = allProperties.concat(processed);
              const total = response.data.total;
              totalPages = Math.ceil(total / pageSize);
              console.log(`Total de páginas calculadas: ${totalPages}`);
              currentPage += 1;
            } else {
              console.warn(`No se encontraron propiedades en la página ${currentPage}.`);
              break;
            }
          } catch (err) {
            console.error(`Error al obtener la página ${currentPage}:`, err);
            if (err.response && err.response.status === 502) {
              // Reintentar la solicitud después de un retraso
              console.log('Reintentando la solicitud después de un error 502...');
              await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos
              // No incrementamos currentPage para reintentar la misma página
            } else {
              throw err; // Propagar otros errores
            }
          }
        }

        // Extraer filtros únicos
        const tiposSet = new Set();
        const regionesSet = new Set();
        const comunasSet = new Set();
        const operacionesSet = new Set();

        allProperties.forEach(propiedad => {
          if (propiedad.property_type) tiposSet.add(propiedad.property_type);
          if (propiedad.location.state) regionesSet.add(propiedad.location.state);
          if (propiedad.location.city) comunasSet.add(propiedad.location.city);
          if (propiedad.transaction_type) operacionesSet.add(propiedad.transaction_type);
        });

        console.log('Tipos de Propiedad extraídos:', Array.from(tiposSet));
        console.log('Regiones extraídas:', Array.from(regionesSet));
        console.log('Comunas extraídas:', Array.from(comunasSet));
        console.log('Operaciones extraídas:', Array.from(operacionesSet));

        commit('SET_TIPOS_PROPIEDAD', Array.from(tiposSet).filter(Boolean).sort());
        commit('SET_REGIONES', Array.from(regionesSet).filter(Boolean).sort());
        commit('SET_COMUNAS', Array.from(comunasSet).filter(Boolean).sort());
        commit('SET_OPERACIONES', Array.from(operacionesSet).filter(Boolean).sort());

        // Marcar que las opciones de filtros ya han sido obtenidas
        commit('SET_FILTER_OPTIONS_FETCHED', true);
      } catch (err) {
        console.error(err);
        commit('SET_ERROR', 'Error al obtener opciones de filtros');
        commit('SET_TIPOS_PROPIEDAD', []);
        commit('SET_REGIONES', []);
        commit('SET_COMUNAS', []);
        commit('SET_OPERACIONES', []);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    // Aplicar filtros
    applyFilters({ commit, dispatch }, filters) {
      commit('SET_CURRENT_FILTERS', filters);
      commit('SET_CURRENT_PAGE', 1);
      dispatch('fetchProperties');
    },
    // Cambiar de página
    changePage({ commit, dispatch }, page) {
      commit('SET_CURRENT_PAGE', page);
      dispatch('fetchProperties');
    },
    // Cambiar la cantidad de propiedades por página
    changeItemsPerPage({ commit, dispatch }, items) {
      commit('SET_ITEMS_PER_PAGE', items);
      commit('SET_CURRENT_PAGE', 1);
      dispatch('fetchProperties');
    },
  },
  getters: {
    // Puedes agregar getters si es necesario
  },
});
