<!-- src/components/CreateProperty.vue -->
<template>
    <div class="container create-property-container">
      <h2 class="mb-4">Agregar Nueva Propiedad</h2>
      
      <form @submit.prevent="submitProperty">
        <div class="mb-3">
          <label for="title" class="form-label">Título de la Propiedad</label>
          <input type="text" v-model="property.title" id="title" class="form-control" required />
        </div>
        
        <div class="mb-3">
          <label for="description" class="form-label">Descripción</label>
          <textarea v-model="property.description" id="description" class="form-control" rows="4" required></textarea>
        </div>
        
        <div class="row g-3">
          <div class="col-md-3">
            <label for="propertyType" class="form-label">Tipo de Propiedad</label>
            <select v-model="property.property_type" id="propertyType" class="form-select" required>
              <option value="" disabled>Seleccionar tipo</option>
              <option>Casa</option>
              <option>Apartamento</option>
              <option>Terreno</option>
              <!-- Agrega más opciones según tus necesidades -->
            </select>
          </div>
          
          <div class="col-md-3">
            <label for="transactionType" class="form-label">Tipo de Operación</label>
            <select v-model="property.transaction_type" id="transactionType" class="form-select" required>
              <option value="" disabled>Seleccionar operación</option>
              <option>Venta</option>
              <option>Arriendo</option>
              <option>Arriendo temporal</option>
              <!-- Agrega más opciones según tus necesidades -->
            </select>
          </div>
          
          <div class="col-md-3">
            <label for="price" class="form-label">Precio</label>
            <input type="number" v-model.number="property.price" id="price" class="form-control" required />
          </div>
          
          <div class="col-md-3">
            <label for="currency" class="form-label">Moneda</label>
            <select v-model="property.currency" id="currency" class="form-select" required>
              <option value="" disabled>Seleccionar moneda</option>
              <option>CLP</option>
              <option>UF</option>
              <option>USD</option>
              <!-- Agrega más opciones según tus necesidades -->
            </select>
          </div>
          <!-- Campo de Dirección con Autocompletado -->
        <div class="mb-3 mt-3">
            <label for="address" class="form-label">Dirección</label>
            <GMapAutocomplete
            id="address"
            v-model="property.address"
            @place_changed="handlePlaceChange"
            :options="autocompleteOptions"
            placeholder="Ingrese dirección completa"
            class="form-control"
            required
            />
        </div>
          <div class="col-md-3">
            <label for="Departamento, Nro casa, otro" class="form-label">Departamento, Nro casa, otro</label>
            <input type="text" v-model="property.nro_prop" id="nro_prop" class="form-control" required />
          </div>
        </div>
        
      
        
        <div class="mb-3">
          <label for="images" class="form-label">Imágenes</label>
          <input type="file" @change="handleFileUpload" id="images" class="form-control" multiple accept="image/*" />
        </div>
        
        <button type="submit" class="btn btn-primary">Publicar Propiedad</button>
        <router-link to="/dashboard" class="btn btn-secondary ms-2">Cancelar</router-link>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CreateProperty',
    data() {
      return {
        property: {
          title: '',
          description: '',
          property_type: '',
          transaction_type: '',
          price: 0,
          currency: '',
          city: '',
          images: [],
        },
        uploadedImages: [],
      };
    },
    methods: {
      handleFileUpload(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          this.uploadedImages.push(files[i]);
        }
      },
      submitProperty() {
        // Aquí implementarás la lógica para enviar los datos al backend
        // Por ahora, simplemente mostramos los datos en la consola
        console.log('Propiedad a publicar:', this.property);
        console.log('Imágenes subidas:', this.uploadedImages);
        alert('Propiedad publicada exitosamente (simulación).');
        
        // Limpiar el formulario
        this.property = {
          title: '',
          description: '',
          property_type: '',
          transaction_type: '',
          price: 0,
          currency: '',
          city: '',
          images: [],
        };
        this.uploadedImages = [];
      },
    },
  };
  </script>
  
  <style scoped>
  .create-property-container {
    padding: 20px;
  }
  
  .form-label {
    font-weight: 500;
  }
  </style>
  