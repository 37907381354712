<!-- src/components/RegionProperty.vue -->
<template>
  <div class="region-property-container container my-5">
    <h2 class="mb-4 text-center">Propiedades en la Región {{ regionName }}</h2>
    <p class="mb-4 text-center">Listado de comunas y propiedades disponibles en la región de {{ regionName }}.</p>
    
    <div class="row">
      <!-- Sidebar Izquierdo: Filtros y Lista de Comunas -->
      <div class="col-lg-3 mb-4">
        <!-- Filtros -->
        <div class="card shadow-sm mb-4">
          <div class="card-header bg-primary text-white">
            <h5 class="mb-0">Filtros de Búsqueda</h5>
          </div>
          <div class="card-body">
            <!-- Tipo de Transacción -->
            <div class="mb-3">
              <label for="transactionType" class="form-label fw-bold">Tipo de Transacción</label>
              <select id="transactionType" class="form-select" v-model="filters.transactionType" @change="applyFilters">
                <option value="">Cualquiera</option>
                <option value="For Sale">Venta</option>
                <option value="For Rent">Arriendo</option>
              </select>
            </div>
            
            <!-- Número de Dormitorios -->
            <div class="mb-3">
              <label for="rooms" class="form-label fw-bold">Número de Dormitorios</label>
              <select id="rooms" class="form-select" v-model="filters.rooms" @change="applyFilters">
                <option value="">Cualquiera</option>
                <option v-for="room in roomOptions" :key="room" :value="room">{{ room }} Dormitorio(s)</option>
              </select>
            </div>
            
            <!-- Número de Estacionamientos -->
            <div class="mb-3">
              <label for="parking" class="form-label fw-bold">Número de Estacionamientos</label>
              <select id="parking" class="form-select" v-model="filters.parking" @change="applyFilters">
                <option value="">Cualquiera</option>
                <option v-for="park in parkingOptions" :key="park" :value="park">{{ park }} Estacionamiento(s)</option>
              </select>
            </div>
            
            <!-- Rango de Precios -->
            <div class="mb-3">
              <label class="form-label fw-bold">Rango de Precios</label>
              <div class="d-flex gap-2">
                <input 
                  type="number" 
                  class="form-control" 
                  placeholder="Desde" 
                  v-model.number="filters.priceFrom" 
                  @input="applyFilters">
                <input 
                  type="number" 
                  class="form-control" 
                  placeholder="Hasta" 
                  v-model.number="filters.priceTo" 
                  @input="applyFilters">
              </div>
            </div>
            
            <!-- Botón para Limpiar Filtros -->
            <div class="d-grid">
              <button class="btn btn-outline-secondary" @click="clearFilters">Limpiar Filtros</button>
            </div>
          </div>
        </div>
        
        <!-- Lista de Comunas -->
        <div class="card shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="mb-0">Comunas de la región</h5>
          </div>
          <div class="card-body p-0">
            <ul class="list-group list-group-flush">
              <li 
                v-for="comuna in comunas" 
                :key="comuna.comuna" 
                class="list-group-item d-flex justify-content-between align-items-center comuna-item"
                @click="filterByComuna(comuna.comuna)"
                :class="{ active: selectedComuna === comuna.comuna }"
                style="cursor: pointer;">
                <span>{{ comuna.comuna }}</span>
                <span class="badge bg-secondary rounded-pill">{{ comuna.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Contenido Principal: Lista de Propiedades -->
      <div class="col-lg-9">
        <!-- Estado de Carga -->
        <div v-if="loading" class="text-center my-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>

        <!-- Mensaje de Error -->
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>

        <!-- Lista de Propiedades -->
        <div v-if="!loading && !error && properties.length > 0" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          <div class="col" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class="card tarjeta-propiedad h-100 shadow-sm">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                <div class="imagen-propiedad position-relative">
                  <div class="tipo-operacion badge bg-primary position-absolute top-0 end-0 m-2">
                    {{ propiedad.transaction_type }}
                  </div>
                  <img
                    :src="getMainImage(propiedad.images)"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title"
                    @error="handleImageError($event)">
                  <div class="precio-propiedad badge bg-success position-absolute bottom-0 start-0 m-2">
                    {{ propiedad.currency }} {{ formatPrice(propiedad.price) }}
                  </div>
                </div>
              </router-link>
              <div class="card-body">
                <h5 class="card-title">
                  <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none text-dark">
                    {{ propiedad.title }}
                  </router-link>
                </h5>
                <div class="tipo-propiedad fw-bold mb-2">
                  {{ propiedad.property_type }}
                </div>

                <!-- Información de la Propiedad -->
                <div class="info-propiedad">
                  <p class="mb-1">
                    <strong>{{ propiedad.rooms }}</strong> Dormitorio(s) |
                    <strong>{{ propiedad.bathrooms }}</strong> Baño(s)
                  </p>
                  <p class="mb-1">
                    {{ propiedad.floor_area }} m² totales |
                    {{ propiedad.parking }} Estacionamiento(s)
                  </p>
                  <p class="mb-1">
                    <strong>Comuna:</strong> {{ propiedad.city }}
                  </p>
                </div>
                <div class="agente-propiedad mt-2">
                  <span class="text-muted">Agente: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mensaje Informativo si No Hay Propiedades -->
        <div v-if="!loading && !error && properties.length === 0" class="alert alert-warning">
          No se encontraron propiedades para la página seleccionada.
        </div>

        <!-- Paginación -->
        <nav aria-label="Page navigation" v-if="totalPages > 1">
          <ul class="pagination justify-content-center">
            <!-- Botón de Página Anterior -->
            <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" href="#" aria-label="Anterior" @click.prevent="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <!-- Páginas -->
            <li
              v-for="page in paginationRange"
              :key="page"
              :class="['page-item', { active: currentPage === page }, { disabled: page === '...' }]">
              <a
                v-if="page !== '...'"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)">
                {{ page }}
              </a>
              <span v-else class="page-link disabled">...</span>
            </li>

            <!-- Botón de Página Siguiente -->
            <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" href="#" aria-label="Siguiente" @click.prevent="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegionProperty',
  props: ['name'], // Nombre de la región pasado como prop
  data() {
    return {
      regionName: '',           // Nombre de la región en mayúsculas
      comunas: [],              // Lista de comunas con conteo de propiedades obtenida de la API
      selectedComuna: null,     // Comuna seleccionada para filtrar
      properties: [],           // Propiedades obtenidas de la API
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 18,         // Número de propiedades por página
      perPageOptions: [18, 32, 50],
      total: 0,
      
      // Filtros
      filters: {
        transactionType: '',    // 'For Sale', 'For Rent' o ''
        rooms: '',              // Número de habitaciones o ''
        parking: '',            // Número de estacionamientos o ''
        priceFrom: null,        // Precio mínimo
        priceTo: null,          // Precio máximo
      },
      
      // Opciones para los filtros
      roomOptions: [1, 2, 3, 4, 5],
      parkingOptions: [0, 1, 2, 3, 4],
    };
  },
  computed: {
    // Número total de páginas
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    // Rango de páginas para mostrar en la paginación
    paginationRange() {
      const range = [];
      const delta = 2;

      const start = Math.max(2, this.currentPage - delta);
      const end = Math.min(this.totalPages - 1, this.currentPage + delta);

      range.push(1);

      if (start > 2) {
        range.push('...');
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      if (end < this.totalPages - 1) {
        range.push('...');
      }

      if (this.totalPages > 1) {
        range.push(this.totalPages);
      }

      return range;
    },
    // Propiedades a mostrar en la página actual
    paginatedProperties() {
      // Implementar paginación si es necesario
      // Actualmente muestra todas las propiedades en la página
      return this.properties;
    },
  },
  methods: {
    // Formatea el precio con puntos como separadores de miles
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    // Función para obtener las comunas de la región desde la API
    fetchComunasForRegion() {
      const comunasApiUrl = "https://2sr8k2jkp1.execute-api.us-east-1.amazonaws.com/Prod/properties/comuna/?region="+this.regionName.toUpperCase();


      console.error("URL"+comunasApiUrl)

   

      axios
        .get(comunasApiUrl)
        .then((response) => {
          console.log('Respuesta de la API de Comunas:', response.data);
          if (response.data && Array.isArray(response.data)) {
            console.log(response.data)
            // Asignar las comunas directamente ya que la API ya las filtra
            this.comunas = response.data
              .map(comuna => ({
                comuna: comuna.comuna,
                count: comuna.count,
              }))
              .sort((a, b) => b.count - a.count); // Ordenar de mayor a menor
            console.log('Comunas asignadas:', this.comunas);
            // Después de obtener las comunas, obtener las propiedades
            this.fetchProperties();
          } else {
            console.warn('Respuesta inesperada de la API de Comunas.');
            this.comunas = [];
            this.error = 'No se pudo procesar la información de comunas.';
            this.loading = false;
          }
        })
        .catch((err) => {
          console.error('Error al obtener comunas:', err);
          if (err.response) {
            console.error('Código de estado:', err.response.status);
            console.error('Datos de la respuesta:', err.response.data);
            if (err.response.status === 403) {
              this.error = 'Acceso denegado a las comunas. Por favor, verifica tus permisos.';
            } else {
              this.error = `Error al obtener comunas: ${err.response.statusText}`;
            }
          } else if (err.request) {
            console.error('Solicitud hecha pero no hubo respuesta:', err.request);
            this.error = 'No se recibió respuesta de la API de comunas.';
          } else {
            console.error('Error en la configuración de la solicitud:', err.message);
            this.error = 'Error al configurar la solicitud para obtener comunas.';
          }
          this.comunas = [];
          this.loading = false;
        });
    },
    // Función para obtener las propiedades de la región desde la API con filtros
    fetchProperties() {
      
      const propertiesApiUrl = "https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search";

      // Construir los parámetros de la solicitud basados en los filtros
      const params = {
        state: this.regionName,   // Usar el nombre de la región
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };

      // Añadir filtros si están seleccionados
      if (this.selectedComuna) {
        params.city = this.selectedComuna;
      }
      if (this.filters.transactionType) {
        params.transaction_type = this.filters.transactionType;
      }
      if (this.filters.rooms) {
        params.rooms = this.filters.rooms;
      }
      if (this.filters.parking !== '') {
        params.parking = this.filters.parking;
      }
      if (this.filters.priceFrom !== null && this.filters.priceFrom !== '') {
        params.price_min = this.filters.priceFrom;
      }
      if (this.filters.priceTo !== null && this.filters.priceTo !== '') {
        params.price_max = this.filters.priceTo;
      }

      console.log('Solicitando propiedades con los siguientes parámetros:', params);

      axios
        .get(propertiesApiUrl, { params })
        .then((response) => {
          console.log('Respuesta de la API de Propiedades:', response.data);
          if (response.data && response.data.properties) {
            this.properties = response.data.properties.map((propiedad) =>
              this.processProperty(propiedad)
            );
            this.total = response.data.total;
            console.log('Propiedades asignadas:', this.properties);
          } else {
            this.error = 'No se encontraron propiedades en la región seleccionada.';
            this.properties = [];
            this.total = 0;
          }
        })
        .catch((err) => {
          console.error('Error al obtener propiedades:', err);
          if (err.response) {
            console.error('Código de estado:', err.response.status);
            console.error('Datos de la respuesta:', err.response.data);
            if (err.response.status === 403) {
              this.error = 'Acceso denegado a las propiedades. Por favor, verifica tus permisos.';
            } else {
              this.error = `Error al obtener propiedades: ${err.response.statusText}`;
            }
          } else if (err.request) {
            console.error('Solicitud hecha pero no hubo respuesta:', err.request);
            this.error = 'No se recibió respuesta de la API de propiedades.';
          } else {
            console.error('Error en la configuración de la solicitud:', err.message);
            this.error = 'Error al configurar la solicitud para obtener propiedades.';
          }
          this.properties = [];
          this.total = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Procesa cada propiedad según la estructura de datos actual
    processProperty(propiedad) {
      console.log('Propiedad recibida para procesar:', propiedad);
      
      // Función auxiliar para obtener el valor de un atributo
      const getAttributeValue = (name) => {
        const attr = propiedad.attributes.find(attr => attr.name === name);
        return attr ? attr.value : 'Sin Datos';
      };

      const processed = {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency || 'CLP',
        property_type: getAttributeValue('PropertyType') || 'Sin Tipo',
        transaction_type: getAttributeValue('TransactionType') || 'Sin Tipo de Transacción',
        rooms: getAttributeValue('Rooms') || 'Sin Datos',
        bathrooms: getAttributeValue('Bathrooms') || 'Sin Datos',
        floor_area: getAttributeValue('FloorArea') || 'Sin Datos',
        plot_area: getAttributeValue('PlotArea') || 'Sin Datos',
        year: getAttributeValue('Year') || 'Sin Datos',
        parking: getAttributeValue('Parking') || 'Sin Datos',
        cellar: getAttributeValue('Cellar') || 'Sin Datos',
        orientation: getAttributeValue('Orientation') || 'Sin Datos',
        is_furnished: getAttributeValue('IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : 'Sin Datos',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : 'Sin Datos',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : 'Sin Datos',
        location: propiedad.location || {},
        city: propiedad.city || (propiedad.location ? propiedad.location.city : 'Sin Datos'),
        images: propiedad.pictures ? propiedad.pictures.map(pic => pic.source) : [],
      };

      console.log('Propiedad procesada:', processed);
      return processed;
    },
    // Obtener la imagen principal de la propiedad
    getMainImage(images) {
      if (images && images.length > 0) {
        return images[0];
      }
      // Si no hay imágenes, retornar la imagen por defecto
      return require('@/assets/regions/default-property.jpg');
    },
    // Filtrar las propiedades por comuna desde el sidebar
    filterByComuna(comuna) {
      if (this.selectedComuna === comuna) {
        // Si ya está seleccionada, deseleccionar
        this.selectedComuna = null;
      } else {
        this.selectedComuna = comuna;
      }
      this.currentPage = 1; // Reiniciar a la primera página al filtrar
      this.loading = true;
      this.error = null;
      this.fetchProperties();
    },
    // Aplicar filtros y reiniciar la paginación
    applyFilters() {
      this.currentPage = 1; // Reiniciar a la primera página al aplicar filtros
      this.loading = true;
      this.error = null;
      this.fetchProperties();
    },
    // Limpiar todos los filtros
    clearFilters() {
      this.filters = {
        transactionType: '',
        rooms: '',
        parking: '',
        priceFrom: null,
        priceTo: null,
      };
      this.applyFilters();
    },
    // Cambiar a una página específica
    changePage(page) {
      if (page < 1) return;
      if (page > this.totalPages) return;
      this.currentPage = page;
      this.loading = true;
      this.error = null;
      this.fetchProperties();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    // Manejar errores de carga de imágenes
    handleImageError(event) {
      event.target.src = require('@/assets/regions/default-property.jpg'); // Imagen por defecto
    },
    // Helper para obtener el nombre de región para la API
    getApiRegionName(regionFullName) {
      // Retornar el nombre en mayúsculas
      return regionFullName.toUpperCase();
    },
  },
  mounted() {
    // Asignar el nombre original de la región en mayúsculas para la API
    this.regionName = this.name.toUpperCase();
    console.log(`Nombre de la región para la API: ${this.regionName}`);
    
    // Obtener las comunas y luego las propiedades
    this.fetchComunasForRegion();
  },
};
</script>

<style scoped>
.region-property-container {
  padding: 20px;
}

.region-property-container h2 {
  margin-bottom: 20px;
}

.region-property-container p {
  margin-bottom: 30px;
}

.card {
  border: none;
}

.list-group-item {
  transition: background-color 0.3s, color 0.3s;
}

.list-group-item.active {
  background-color: #919191;
  border-color: #919191;
  color: white;
}

.list-group-item:hover {
  background-color: #f1f1f1;
  color: #343a40;
}

.comuna-item {
  padding: 15px 20px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.badge {
  font-size: 0.9rem;
}

.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.imagen-propiedad {
  position: relative;
}

.tipo-operacion {
  /* Puedes personalizar el color y el estilo según tu preferencia */
}

.precio-propiedad {
  /* Puedes personalizar el color y el estilo según tu preferencia */
}

.tipo-propiedad {
  color: #FF8C00; /* Naranja oscuro para resaltar */
  font-weight: bold;
  text-align: left;
}

.info-propiedad p {
  text-align: left;
  color: #585858;
  margin-bottom: 0.5rem;
}

.agente-propiedad {
  margin-top: 12px;
  font-size: 0.875rem;
  text-align: left;
}

.card-img,
.card-img-top {
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.card-title a {
  margin-bottom: 0.5rem;
  color: #343a40;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
}

.card-title a:hover {
  color: #0d6efd;
}

.tipo-propiedad.fw-bold {
  text-align: left;
  color: #343a40;
}

.pagination {
  margin-top: 20px;
}

.pagination .page-link {
  cursor: pointer;
}

@media (max-width: 992px) {
  /* Ajustes para pantallas medianas y pequeñas */
  .col-lg-3 {
    order: 2; /* Mover el sidebar debajo en pantallas pequeñas */
  }
  .col-lg-9 {
    order: 1;
  }
}

/* Estilos personalizados para los filtros */
.card-header.bg-primary.text-white {
  background: #181717 !important;
}

.card-header.bg-primary.text-white h5 {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.form-label.fw-bold {
  font-weight: 600;
  font-size: 0.9rem;
}

.form-select, .form-control {
  font-size: 0.9rem;
}

.btn-outline-secondary {
  font-size: 0.9rem;
}

.comuna-item[data-v-b539b2ce] {
    padding: 6px 20px;
    font-size: 0.8rem;
}

span.badge.bg-secondary.rounded-pill {
    background: #fff !important;
    color: #645f5f !important;
    font-size: 0.7rem;
    border: solid 1px;
}

.card-header.bg-primary.text-white {
    background: #181717 !important;
}
.card-header.bg-primary.text-white h5 {
    font-size: 1rem !important;
    font-weight: 400 !important;
}
</style>
