/* eslint-disable */
<!-- HomePage.vue -->
<template>
  <div id="app">
    <!-- Carrusel en el Encabezado -->
    <div class="carousel-container mb-5">
      <div id="headerCarousel" class="carousel slide" data-bs-ride="carousel">
        <!-- Indicadores del Carrusel -->
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2">
          </button>
          <button
            type="button"
            data-bs-target="#headerCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3">
          </button>
        </div>

        <!-- Elementos del Carrusel -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../assets/slide/1.jpg"
              class="d-block w-100"
              alt="Slide 1">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/2.jpg"
              class="d-block w-100"
              alt="Slide 2">
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide/3.jpg"
              class="d-block w-100"
              alt="Slide 3">
          </div>
        </div>

        <!-- Controles del Carrusel -->
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#headerCarousel"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Siguiente</span>
        </button>
      </div>
    </div>
    <!-- Fin del Carrusel -->

    <div class="container mt-5">
      <!-- Formulario de Filtros -->
      <form @submit.prevent="applyFilters" class="mb-4">
        <div class="row g-3">
          <!-- Filtro de Ubicación (Región y Comuna) -->
          <div class="col-md-3 position-relative">
            <label for="location" class="form-label">Región o Comuna</label>
            <input
              type="text"
              v-model="selectedLocation"
              id="location"
              class="form-control"
              @input="onLocationInput"
              @blur="onLocationBlur"
              @focus="onLocationFocus"
              autocomplete="off"
            />
            <!-- Lista de sugerencias -->
            <ul v-if="showLocationSuggestions" class="list-group position-absolute w-100 autocomplete-dropdown">
              <li
                class="list-group-item list-group-item-action"
                v-for="location in filteredLocations"
                :key="location.key"
                @mousedown.prevent="selectLocation(location)"
              >
                {{ location.descripcion }}
                <span class="badge bg-secondary float-end">{{ location.type }}</span>
              </li>
            </ul>
          </div>

          <!-- Filtro Operación -->
          <div class="col-md-3">
            <label for="operacion" class="form-label">Operación</label>
            <select v-model="selectedOperacion" id="operacion" class="form-select">
              <option value="">Seleccionar operación</option>
              <option
                v-for="operacion in operaciones"
                :key="operacion.valor"
                :value="operacion.descripcion"
              >
                {{ operacion.descripcion }}
              </option>
            </select>
          </div>

          <!-- Otros filtros (opcional) -->
          <!-- Aquí puedes agregar más filtros si lo deseas -->

        </div>

        <div class="mt-3">
          <button type="submit" class="btn btn-primary">Buscar</button>
          <button type="button" class="btn btn-secondary ms-2" @click="resetFilters">Resetear Filtros</button>
        </div>
      </form>

      <!-- Mensaje de error -->
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <!-- Estado de carga -->
      <div v-if="loading" class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>

      <!-- Resultados de la búsqueda -->
      <div v-if="!loading && !error">
        <!-- Sección de Selector de Cantidad por Página y Resultados -->
        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="p-result">{{ total }} resultados en la búsqueda.</p>
          <div class="d-flex align-items-center">
            <label for="itemsPerPage" class="me-2 mb-0">Propiedades por página:</label>
            <select id="itemsPerPage" v-model.number="itemsPerPage" @change="changeItemsPerPage" class="form-select w-auto">
              <option v-for="option in perPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>

        <!-- Lista de Propiedades con 4 por fila -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 propiedades-listado">
          <div class="col mb-3" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class=" tarjeta-propiedad h-100">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                <div class="imagen-propiedad position-relative">
                  <div class="tipo-operacion badge bg-primary position-absolute top-0 end-0 m-2">
                    {{ propiedad.transaction_type }}
                  </div>
                  <img
                    :src="propiedad.images[0]"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title">
                  <div class="precio-propiedad badge bg-success position-absolute bottom-0 start-0 m-2">
                    {{ propiedad.currency }} {{ formatPrice(propiedad.price) }}
                  </div>
                </div>
              </router-link>
              <div class="card-body">
                <h5 class="card-title">
                  <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                    {{ propiedad.title }}
                  </router-link>
                </h5>
                <div class="tipo-propiedad fw-bold">
                  {{ propiedad.property_type }}
                </div>

                <!-- Información de la Propiedad -->
                <div class="info-propiedad">
                  <p>
                    <strong>{{ propiedad.rooms }}</strong> Dormitorios |
                    <strong>{{ propiedad.bathrooms }}</strong> Baños
                  </p>
                  <p>
                    {{ propiedad.floor_area }} m² totales |
                    {{ propiedad.parking }} Estacionamientos
                  </p>
                  <p>
                    <strong>Comuna:</strong> {{ propiedad.city }}
                  </p>
                </div>
                <div class="agente-propiedad">
                  <span>Agente: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Controles de Paginación -->
        <nav aria-label="Page navigation example" v-if="totalPages > 1">
          <ul class="pagination justify-content-center">
            <!-- Botón de Página Anterior -->
            <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" href="#" aria-label="Anterior" @click.prevent="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <!-- Páginas -->
            <li
              v-for="page in paginationRange"
              :key="page"
              :class="['page-item', { active: currentPage === page }, { disabled: page === '...' }]">
              <a
                v-if="page !== '...'"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)">
                {{ page }}
              </a>
              <span v-else class="page-link disabled">...</span>
            </li>

            <!-- Botón de Página Siguiente -->
            <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" href="#" aria-label="Siguiente" @click.prevent="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <!-- Sección Nueva: Tarjetas Dinámicas de Regiones -->
      <div class="mt-5">
        <br>
        <h3 class="mb-4 mt-5 text-center">Explora las propiedades por regiones de Chile</h3>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-6 g-6">
          <div class="col cont.reg" v-for="region in allRegions" :key="region.valor">
            <div class="region-card text-center h-100">
              <div class="card-body d-flex flex-column align-items-center justify-content-center">
               
                <router-link :to="`/region/${getTrimmedRegionName(region.descripcion)}`" class="mt-auto">
                  <img
                    :src="getRegionImage(region.descripcion)"
                    :alt="`Región de ${getTrimmedRegionName(region.descripcion)}`"
                    class="region-image"
                    @error="handleImageError($event)">
                  <h5 class="card-title">{{ getTrimmedRegionName(region.descripcion) }}</h5>
                </router-link>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Fin de la Sección Nueva -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      properties: [],
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 12,
      perPageOptions: [12, 24, 48],
      total: 0,

      // Opciones de filtros
      tiposPropiedad: [],
      regiones: [],
      comunas: [],
      operaciones: [],

      // Filtros seleccionados
      selectedTipoPropiedad: '',
      selectedOperacion: '',
      selectedLocation: '',
      selectedLocationType: '',
      selectedLocationValue: '',

      // Datos para autocompletado de ubicación
      locations: [],
      filteredLocations: [],
      showLocationSuggestions: false,

      // Datos de todas las regiones para las tarjetas dinámicas
      allRegions: [],
    };
  },
  computed: {
    isAuthenticated() {
      return !!this.$clerk.user;
    },
    // Número total de páginas
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    // Propiedades a mostrar en la página actual
    paginatedProperties() {
      return this.properties;
    },
    // Rango de páginas para mostrar en la paginación
    paginationRange() {
      const range = [];
      const delta = 2;

      const start = Math.max(2, this.currentPage - delta);
      const end = Math.min(this.totalPages - 1, this.currentPage + delta);

      range.push(1);

      if (start > 2) {
        range.push('...');
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      if (end < this.totalPages - 1) {
        range.push('...');
      }

      if (this.totalPages > 1) {
        range.push(this.totalPages);
      }

      return range;
    },
  },
  methods: {
    // Formatea el precio con puntos como separadores de miles
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    // Función para obtener las propiedades desde la API con filtros
    fetchFilteredProperties() {
      this.loading = true;
      this.error = null;

      const apiUrl = "https://eh66w4x2x3.execute-api.us-east-1.amazonaws.com/Prod/search";

      const params = {
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };

      if (this.selectedTipoPropiedad) {
        params.property_type = this.selectedTipoPropiedad;
      }
      if (this.selectedOperacion) {
        // Asumiendo que la API espera la descripción
        params.transaction_type = this.selectedOperacion;
        // Si la API espera un código numérico, utiliza el valor correspondiente
        /*
        const operacion = this.operaciones.find(
          (op) => op.descripcion === this.selectedOperacion
        );
        if (operacion) {
          params.transaction_type = operacion.valor;
        }
        */
      }
      if (this.selectedLocation && this.selectedLocationType) {
        if (this.selectedLocationType === 'Región') {
          params.state = this.selectedLocationValue;
        } else if (this.selectedLocationType === 'Comuna') {
          params.city = this.selectedLocationValue;
        }
      }

      console.log('Parámetros enviados a la API:', params);

      axios
        .get(apiUrl, {
          headers: {
            Accept: 'application/json',
          },
          params: params,
        })
        .then((response) => {
          console.log('Respuesta de la API:', response.data);
          if (response.data && response.data.properties) {
            this.properties = response.data.properties.map((propiedad) =>
              this.processProperty(propiedad)
            );
            this.total = response.data.total;

            // Extraer opciones de filtros desde las propiedades actuales
            this.extractFilterOptions(this.properties);
          } else {
            this.error = 'No se encontraron propiedades con los filtros aplicados';
            this.properties = [];
            this.total = 0;
            this.tiposPropiedad = [];
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.status === 404) {
            this.error = 'Ruta de API no encontrada (404)';
          } else {
            this.error = 'Error al obtener datos';
          }
          this.properties = [];
          this.total = 0;
          this.tiposPropiedad = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Procesa cada propiedad para mapear los campos necesarios
    processProperty(propiedad) {
      const transactionTypeValue =
        propiedad.transaction_type ||
        this.getAttributeValue(propiedad.attributes, 'TransactionType');

      const transactionTypeDescripcion = this.getOperacionDescripcion(transactionTypeValue);

      const processed = {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency,
        property_type:
          propiedad.property_type ||
          this.getAttributeValue(propiedad.attributes, 'PropertyType'),
        transaction_type: transactionTypeDescripcion,
        rooms: this.getAttributeValue(propiedad.attributes, 'Rooms'),
        bathrooms: this.getAttributeValue(propiedad.attributes, 'Bathrooms'),
        floor_area: this.getAttributeValue(propiedad.attributes, 'FloorArea'),
        plot_area: this.getAttributeValue(propiedad.attributes, 'PlotArea'),
        year: this.getAttributeValue(propiedad.attributes, 'Year'),
        parking: this.getAttributeValue(propiedad.attributes, 'Parking'),
        cellar: this.getAttributeValue(propiedad.attributes, 'Cellar'),
        orientation: this.getAttributeValue(propiedad.attributes, 'Orientation'),
        is_furnished: this.getAttributeValue(propiedad.attributes, 'IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : '',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : '',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : '',
        location: propiedad.location,
        city: propiedad.city || (propiedad.location ? propiedad.location.city : ''),
        images: propiedad.pictures.map((pic) => pic.source),
      };

      return processed;
    },
    // Obtiene el valor de un atributo por su nombre
    getAttributeValue(attributes, name) {
      const attr = attributes.find((attr) => attr.name === name);
      return attr ? attr.value : '';
    },
    // Obtiene la descripción de la operación a partir del valor
    getOperacionDescripcion(valor) {
      const operacion = this.operaciones.find(
        (op) => op.valor == valor || op.descripcion == valor
      );
      return operacion ? operacion.descripcion : valor;
    },
    // Extrae las opciones únicas para cada filtro
    extractFilterOptions(propiedades) {
      const tiposSet = new Set();

      propiedades.forEach((propiedad) => {
        if (propiedad.property_type) tiposSet.add(propiedad.property_type);
      });

      this.tiposPropiedad = Array.from(tiposSet).filter(Boolean).sort();
    },
    // Aplicar filtros y obtener propiedades filtradas
    applyFilters() {
      this.currentPage = 1;
      this.fetchFilteredProperties();
    },
    // Resetear todos los filtros
    resetFilters() {
      this.selectedTipoPropiedad = '';
      this.selectedOperacion = '';
      this.selectedLocation = '';
      this.selectedLocationType = '';
      this.selectedLocationValue = '';
      this.applyFilters();
    },
    // Cambiar a una página específica
    changePage(page) {
      if (page < 1) return;
      if (page > this.totalPages) return;
      this.currentPage = page;
      this.fetchFilteredProperties();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    // Cambiar la cantidad de propiedades por página
    changeItemsPerPage() {
      this.currentPage = 1;
      this.fetchFilteredProperties();
    },
    // Función para obtener las comunas desde la API
    fetchComunas() {
      const comunasApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/comunas";

      return axios
        .get(comunasApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.comunas = response.data
              .filter((comuna) => comuna.descripcion)
              .map((comuna) => ({
                key: `comuna-${comuna.valor}`,
                descripcion: comuna.descripcion,
                valor: comuna.valor,
                type: 'Comuna',
              }));
            console.log('Comunas obtenidas desde la API:', this.comunas);
          } else {
            console.error('No se encontraron comunas en la API');
            this.comunas = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener comunas:', err);
          this.comunas = [];
        });
    },
    // Función para obtener las regiones desde la API
    fetchRegiones() {
      const regionesApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/regiones";

      return axios
        .get(regionesApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.regiones = response.data
              .filter((region) => region.descripcion)
              .map((region) => ({
                key: `region-${region.valor}`,
                descripcion: region.descripcion,
                valor: region.valor,
                type: 'Región',
              }));
            console.log('Regiones obtenidas desde la API:', this.regiones);
            // Almacenar todas las regiones para las tarjetas dinámicas
            this.allRegions = this.regiones;
          } else {
            console.error('No se encontraron regiones en la API');
            this.regiones = [];
            this.allRegions = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener regiones:', err);
          this.regiones = [];
          this.allRegions = [];
        });
    },
    // Función para obtener los tipos de operación desde la API
    fetchTipoOperacion() {
      const tipoOperacionApiUrl = "https://x509fhy73i.execute-api.us-east-1.amazonaws.com/Prod/tipo_operacion";

      return axios
        .get(tipoOperacionApiUrl)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.operaciones = response.data.map((operacion) => ({
              valor: operacion.valor,
              descripcion: operacion.descripcion,
            }));
            console.log('Operaciones obtenidas desde la API:', this.operaciones);
          } else {
            console.error('No se encontraron tipos de operación en la API');
            this.operaciones = [];
          }
        })
        .catch((err) => {
          console.error('Error al obtener tipos de operación:', err);
          this.operaciones = [];
        });
    },
    // Función para combinar regiones y comunas
    combineLocations() {
      this.locations = [...this.regiones, ...this.comunas].sort((a, b) =>
        a.descripcion.localeCompare(b.descripcion)
      );
    },
    // Métodos para el autocompletado de ubicación
    onLocationInput() {
      if (this.selectedLocation.length > 0) {
        const searchTerm = this.selectedLocation.toLowerCase();
        this.filteredLocations = this.locations.filter((location) =>
          location.descripcion.toLowerCase().includes(searchTerm)
        );
        this.showLocationSuggestions = this.filteredLocations.length > 0;
      } else {
        this.filteredLocations = [];
        this.showLocationSuggestions = false;
        this.selectedLocationType = '';
        this.selectedLocationValue = '';
      }
    },
    selectLocation(location) {
      this.selectedLocation = location.descripcion;
      this.selectedLocationType = location.type;
      this.selectedLocationValue = this.getTrimmedRegionName(location.descripcion); // Usamos el nombre simplificado
      this.filteredLocations = [];
      this.showLocationSuggestions = false;
    },
    onLocationBlur() {
      // Retrasar el ocultamiento para permitir la selección con el mouse
      setTimeout(() => {
        this.showLocationSuggestions = false;
      }, 100);
    },
    onLocationFocus() {
      if (this.filteredLocations.length > 0) {
        this.showLocationSuggestions = true;
      }
    },
    // Método nuevo para navegar al Dashboard
    navigateToDashboard() {
      this.$router.push('/dashboard');
    },
    // Función para simplificar el nombre de la región eliminando "Región de" o "Región del"
    getTrimmedRegionName(descripcion) {
      return descripcion.replace(/^Región de\s+/i, '').replace(/^Región del\s+/i, '').replace(/^Región\s+/i, '').replace(/^Region\s+/i, '')
      .replace(/^Libertador General Bernardo\s+/i, '')
      .replace(/ del General Carlos Ibáñez del Campo$/i, '').replace(/ y la Antártica Chilena $/i, '');
    },
    // Función para obtener la ruta de la imagen de la región
    getRegionImage(descripcion) {
  // Obtener el nombre simplificado de la región
  const trimmedName = this.getTrimmedRegionName(descripcion);
  // Normalizar el nombre para coincidir con el nombre del archivo de imagen
  const imageName = this.normalizeRegionName(trimmedName);
  console.log(`Nombre simplificado: ${trimmedName}, Nombre normalizado para imagen: ${imageName}`);

  try {
    // Retornar la ruta de la imagen
    return require(`@/assets/regions/${imageName}.jpg`);
  } catch (e) {
    console.error(`Imagen para la región "${trimmedName}" no encontrada. Nombre de la imagen: ${imageName}.jpg`);
    // Retornar una imagen por defecto si no se encuentra la imagen específica
    return require(`@/assets/regions/default.jpg`);
  }
},
    // Función para normalizar el nombre de la región (convertir a minúsculas y sin espacios)
    normalizeRegionName(name) {
  return name
    .toLowerCase()
    .normalize("NFD") // Normaliza el string para separar los acentos
    .replace(/[\u0300-\u036f]/g, "") // Elimina los acentos
    .replace(/[^a-z0-9]/g, '');
  },
    // Manejar errores de carga de imágenes
    handleImageError(event) {
      event.target.src = require('@/assets/regions/default.jpg'); // Imagen por defecto
    },
  },
  mounted() {
    // Obtener las regiones, comunas y tipos de operación antes de cargar las propiedades
    Promise.all([this.fetchRegiones(), this.fetchComunas(), this.fetchTipoOperacion()])
      .then(() => {
        this.combineLocations();
        // Ahora que las ubicaciones y tipos de operación están cargados, obtenemos las propiedades
        this.fetchFilteredProperties();
      })
      .catch((err) => {
        console.error('Error al cargar datos iniciales:', err);
        // Aún así, intentamos cargar las propiedades
        this.fetchFilteredProperties();
      });
  },
};
</script>

<style scoped>
/* Estilos para el Navbar */
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  color: #555;
}

.navbar-nav .nav-link:hover {
  color: #0d6efd;
}

/* Estilos para el Carrusel */
.carousel-container {
  width: 80%;
  margin: 0 auto;
  height: 340px;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-container .carousel-item img {
  height: 340px;
  object-fit: cover;
}

/* Ajustes para el formulario de filtros */
form {
  background-color: #e9ebed;
  padding: 20px;
  border-radius: 5px;
  border: solid 2px #cbcdcf;
}

form .form-label {
  font-weight: 500;
}

form button {
  min-width: 100px;
}

/* Estilos para la lista de propiedades */
.propiedades-listado {
  /* Bootstrap grid handles the layout */
}

.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.imagen-propiedad {
  position: relative;
}

.tipo-operacion {
  /* Additional styles if needed */
}

.precio-propiedad {
  /* Additional styles if needed */
}

.tipo-propiedad {
  color: orange;
  font-weight: bold;
  text-align: left;
}

.p-result {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.card-img,
.card-img-top {
  height: 260px;
}

.card-title a {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: #585858;
  text-align: left;
  font-size: 1rem;
}

.tipo-propiedad.fw-bold {
  text-align: left;
  color: #585858;
}

.info-propiedad p {
  text-align: left;
  color: #585858;
  margin-bottom: 0;
}

.agente-propiedad {
  margin-top: 12px;
  font-size: 12px;
  text-align: left;
}

div#app {
  background: #f8f9fa;
}

img.card-img-top {
  object-fit: cover;
}

h5.card-title {
  float: left;
  text-align: left;
  text-transform: uppercase;
}

/* Estilos para la paginación */
.pagination {
  margin-top: 20px;
}

.pagination .page-link {
  cursor: pointer;
}

/* Estilos para el selector de cantidad por página */
#itemsPerPage {
  width: 100px;
}

/* Responsividad */
@media (max-width: 576px) {
  .d-flex.justify-content-between {
    flex-direction: column;
    align-items: flex-start;
  }
  #itemsPerPage {
    width: 100%;
    margin-top: 10px;
  }

  form .row.g-3 {
    flex-direction: column;
  }

  form .col-md-3 {
    width: 100%;
  }
}

/* Ajustes adicionales para el carrusel */
.carousel-container .carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-container .carousel-control-prev-icon,
.carousel-container .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-container .carousel-control-prev-icon:hover,
.carousel-container .carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 5rem;
}

/* Estilos para el botón Buscar */
button.btn.btn-primary {
  background: #181717;
  border: solid 1px #181717;
}

/* Estilos para el autocompletado de ubicación */
.autocomplete-dropdown {
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.position-relative {
  position: relative;
}

/* Estilos para las tarjetas dinámicas de regiones */
.region-card {
  /* Ajusta el ancho según necesites */
  width: 100%;
  border: none;
  transition: transform 0.3s, box-shadow 0.3s;
}
.region-image {
  object-fit: contain;
  width: 100%;
}

.region-card h5.card-title {
  font-size: 1rem;
  margin-bottom: 10px;
}

.region-card p.card-text {
  font-size: 0.85rem;
  margin-bottom: 15px;
}

.region-card h5.card-title[data-v-df02a584] {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
    display: inherit;
    margin-bottom: 10px;
    bottom: 0;
    background: #181717;
    color: #fff;
    text-align: center;
}

.col.cont\.reg {
    padding: 0 3px;
}

.tarjeta-propiedad .card-body {
    padding: 10px 10px;
    background: #ffffff;
}

@media (min-width: 1024px) {
    .col.cont\.reg {
        width: 16%;
    }
}
</style>
