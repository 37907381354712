<!-- src/App.vue -->
<script setup>
import { SignedIn, SignedOut, SignInButton, UserButton } from 'vue-clerk';
</script>

<template>
  <div id="app">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <!-- Logo -->
        <router-link to="/" class="navbar-brand">
          <img 
            src="./assets/ubbi-logo.png" 
            alt="Logo Ubbi" 
            class="d-inline-block align-text-top">
        </router-link>
        <!-- Botón de Toggle para dispositivos móviles -->
        <button 
          class="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarNav" 
          aria-controls="navbarNav" 
          aria-expanded="false" 
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Enlaces del Navbar -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto align-items-center">
            <!-- Botón "¿Quieres publicar?" redirige directamente al dashboard -->
            <li class="nav-item">
              <router-link to="/dashboard" class="btn btn-custom ms-2">
                ¿Quieres publicar?
              </router-link>
            </li>
            <!-- Botón "Tus Propiedades" que se muestra solo si el usuario está autenticado -->
            <SignedIn>
              <li class="nav-item">
                <router-link to="/dashboard" class="nav-link">Tus Propiedades</router-link>
              </li>
            </SignedIn>
            <!-- Botones de inicio de sesión y usuario -->
            <li class="nav-item">
              <SignedOut>
                <SignInButton mode="modal">
                  <button class="btn btn-link nav-link btn-custom">Iniciar Sesión</button>
                </SignInButton>
              </SignedOut>
              <SignedIn>
                <UserButton />
              </SignedIn>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- Fin del Navbar -->
    
    <!-- Contenido Principal -->
    <router-view />
    
    <!-- Footer (Opcional) -->
    <!-- Puedes agregar aquí tu Footer si deseas que aparezca en todas las páginas -->
  </div>
</template>


<script>
export default {
  name: 'App',
};
</script>

<style>
.navbar-brand img {
  margin-right: 10px;
  height: 60px;
}
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
/* Importar Bootstrap CSS */
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Estilos globales personalizados */
body {
  background-color: #f8f9fa;
  font-family: "Nunito Sans", sans-serif;
}

.btn-custom {
  background-color: #181717 !important;
  color: #fff !important;
  border: none;
  padding: 6px 12px; /* Ajusta el padding según necesites */
  border-radius: 4px; /* Opcional: añade bordes redondeados */
  cursor: pointer;
  text-decoration: none; /* Asegura que no tenga subrayado */
}

/* Estilos para enlaces en el navbar */
.navbar-nav .nav-link {
  color: #000;
}

.navbar-nav .nav-link:hover {
  color: #555;
}

/* Estilos específicos para el botón de inicio de sesión */
button.btn.btn-link.nav-link.btn-custom {
  padding-top: 6px;
}

button {
  border: none;
  background: none;
}

/* Opcional: estilos para el footer */
footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  margin-top: 50px;
}
</style>
