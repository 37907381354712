<!-- src/components/PropertyDashboard.vue -->
<template>
    <div class="container dashboard-container">
      <!-- Banner Informativo -->
      <div class="info-banner alert alert-info d-flex justify-content-between align-items-center">
        <span>Si eres corredor, revisa nuestros planes</span>
        <router-link to="/plans" class="btn btn-primary btn-sm">
          Ver Planes
        </router-link>
      </div>
      
      <h2 class="mb-4">Dashboard de Propiedades</h2>
      
      <!-- Botón para agregar una nueva propiedad -->
      <div class="mb-3">
        <router-link to="/create-property" class="btn btn-success">
          Agregar Nueva Propiedad
        </router-link>
      </div>
  
      <!-- Lista de propiedades publicadas -->
      <div v-if="properties.length > 0" class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col" v-for="propiedad in properties" :key="propiedad.id">
          <div class="card h-100">
            <img 
              :src="propiedad.images[0]" 
              class="card-img-top" 
              :alt="'Imagen de ' + propiedad.title">
            <div class="card-body">
              <h5 class="card-title">{{ propiedad.title }}</h5>
              <p class="card-text">
                <strong>Tipo:</strong> {{ propiedad.property_type }}<br>
                <strong>Operación:</strong> {{ propiedad.transaction_type }}<br>
                <strong>Precio:</strong> {{ propiedad.currency }} {{ formatPrice(propiedad.price) }}<br>
                <strong>Comuna:</strong> {{ propiedad.city }}<br>
                <strong>Fecha de Publicación:</strong> {{ formatDate(propiedad.publication_date) }}
              </p>
            </div>
            <div class="card-footer d-flex justify-content-between">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="btn btn-primary btn-sm">
                Ver Detalles
              </router-link>
              <div>
                <button class="btn btn-warning btn-sm me-2" @click="editProperty(propiedad.id)">
                  Editar
                </button>
                <button class="btn btn-danger btn-sm" @click="deleteProperty(propiedad.id)">
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Mensaje si no hay propiedades publicadas -->
      <div v-else class="alert alert-info">
        No has publicado ninguna propiedad aún.
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PropertyDashboard',
    data() {
      return {
        properties: [
          // Datos de ejemplo. Reemplaza esto con llamadas al backend en el futuro.
          {
            id: 1,
            title: 'Apartamento en el Centro',
            property_type: 'Apartamento',
            transaction_type: 'Venta',
            price: 150000000,
            currency: 'CLP',
            city: 'Santiago',
            publication_date: '2024-04-15',
            images: ['../assets/sample-property.jpg'],
          },
          {
            id: 2,
            title: 'Casa en Las Condes',
            property_type: 'Casa',
            transaction_type: 'Arriendo',
            price: 500000,
            currency: 'CLP',
            city: 'Las Condes',
            publication_date: '2024-05-10',
            images: ['../assets/sample-property.jpg'],
          },
        ],
      };
    },
    methods: {
      formatPrice(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      },
      formatDate(dateStr) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateStr).toLocaleDateString('es-CL', options);
      },
      editProperty(id) {
        // Redirigir a la página de edición de la propiedad
        this.$router.push({ name: 'EditProperty', params: { id } });
      },
      deleteProperty(id) {
        // Implementa la lógica para eliminar la propiedad aquí
        // Por ahora, simplemente eliminaremos la propiedad del arreglo local
        this.properties = this.properties.filter(propiedad => propiedad.id !== id);
        alert('Propiedad eliminada exitosamente.');
      },
    },
  };
  </script>
  
  <style scoped>
  .dashboard-container {
    padding: 20px;
  }
  
  /* Estilos para el banner informativo */
  .info-banner {
    background-color: #e9ecef;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 20px;
  }
  
  .info-banner span {
    font-weight: 500;
  }
  
  .info-banner .btn {
    font-size: 0.9rem;
  }
  
  /* Estilos para las tarjetas de propiedades */
  .card-img-top {
    height: 200px;
    object-fit: cover;
  }
  </style>
  